.form-control {
	font-size: 2.4rem;
}





textarea#messageTextarea { 

	min-height: 15rem;
}