@import "../../../styles/_mixins.scss";

.menu-holder {

	.menu-inside {
		position: fixed; 
		width: 100vw;
		height: 100vh;
		background: #1F2933;;
		z-index: 10;

		will-change: transform;
		transition: transform 1s cubic-bezier(1, 0, 0, 1);
		color: #fff;
		transform: translateX(-100%);

		.menu-nav-container{
			position: relative;
			left: 220px;
			top: 100px;
			overflow: hidden;
			width: calc(90vw - 260px);
		}

		.internal-nav-links {
			li{
				margin-bottom: 20px;
				list-style-type: none;

					a {
						font-size: clamp(32px, 4vw, 52px);
						color: #fff;
						line-height: 1.1em;
						
						-webkit-transform: translate(-100%);
						transform: translate(-100%);
						will-change: transform;
						transition: -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
						transition: transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
						transition: transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);	
						
						text-decoration: none;
					}

					&:hover {
						cursor: pointer;

							img {
								opacity: 1;
								transform: translateY(-50%) translateX(30px);
							}
							a {
								font-weight: 800;
								background: linear-gradient(70deg, #03449E, #1CD4D4);
								-webkit-background-clip: text;
								-webkit-text-fill-color: transparent;
							}
					}
					
					img {
						pointer-events: none;
						opacity: 0;
						aspect-ratio: 16/9;
						display: block;
						width: 30vw;
						position: absolute;
						top:50%;
						right:10%;
						object-fit: cover;
						transform: translateY(-50%) translateX(-30px);
						transition: transform 0.5s ease-in-out;

						/*filter: brightness(1.3) grayscale(0.54) contrast(0.8) saturate(1.2) sepia(0.21);*/	
					}
			}
		}

		
		.external-nav-links { 
			margin-top: 100px;
			display: flex;
			flex-wrap: wrap;

			li {
				margin-right: 30px;
				list-style-type: none;

				a {
					color: #fff;

					transform: translate(-100%);
					opacity: 0;
					transition: all 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19);
				
					
				}

				&:last-of-type{
					margin-right: 0;
				}
			}
		}


		&.open {
			transform: translateX(0);

			.internal-nav-links {
			
			li {
				a {
					
					transform: translate(0);
					transition-duration: 0.8s;
					transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
				}
			}

			@for $i from 0 through 4 {
				li:nth-child(#{$i+1}n) {
					a {
					  transition-delay: #{$i/10 + 0.8}s;
					}
				}
			}

			}

			.external-nav-links{

				li {
				a {
					opacity: 1;
					transform: translate(0);
					transition-duration: 1s;
					transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
				}
			}

			@for $i from 0 through 4 {
				li:nth-child(#{$i+1}n) {
					a {
					  transition-delay: #{$i/10 + 1.5}s;
					}
				}
			}

			}

		}

	}

	@include mq('tablet', max)
	{
		.menu-inside {
			.menu-nav-container{
				left: 10%;
				width: 100vw;
				img {
					display: none !important;
				}
			}
		}
	}


}