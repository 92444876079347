@import "../../styles/_mixins.scss";

$dark-blue-1: #002159;
$dark-blue-2: #01337D;
$dark-blue-3: #03449E;

$white-1: #ffffff;
$gray-dark-1: #1F2933;
$gray-light-2: #E4E7EB;

footer { 
	color: $gray-light-2;

	border-top: solid 1px $gray-light-2 !important;

	text-align: center;

	a { 
		color: $gray-light-2;
		}

	#main-footer {

		padding-top: 4rem;
		padding-bottom: 4rem;

		ul.footer-links {
			list-style: none;
			margin: 0;
			padding: 4rem 0;
			li {
				display: inline-block;
				a {
					padding: 0 10px;
					font-weight: 500;

				}
				a.first-item {
						padding: 0 10px 0 0;
					}
			&:after {
			   	color: #fff;
    			content: "•";
			}
			&:last-child:after {
			    content: "";
			}	


			}
		}

	}	


	#copyright-area { 


	}


}

.footer-contact-button { 

		background-color: $gray-light-2;

		border: solid 1px $gray-light-2;
		padding: 20px;

		text-transform: uppercase;

		color: $dark-blue-1;

		display: flex;
		align-items: center;
		justify-content: center;

		transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); 

		letter-spacing: 3px;

		span {
			margin-right: 10px;
		}

		@include mq ('tablet'){
			&:hover{
				background-color: $dark-blue-1;
				color: #fff;
			}
		}

		 @include mq("tablet", max) {
		    margin-top: 2rem;
		    will-change: transform;
		    transition: all 0.2s ease,
		      transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);

		    width: 250px;
		    height: 46px;
		    background-color: $gray-light-2;
		    /** border-radius: 24px; **/
		    text-align: center;
		    transition-delay: 0.4s;

		    span {
		      /** display: none; **/
		    }

		    svg {
		      transition: transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
		      transform: rotate(0) scale(1);
		    }

		    
		  }
		}


@media screen and (min-width: 768px){

	footer { 
	text-align: left; 

	.col-md-8 {
		text-align: right;
		padding-right:5rem;
	}

}

}