@import "../../styles/_mixins.scss";

$dark-blue-1: #002159;
$gray-light-2: #E4E7EB;


.contact-button { 

		background-color: $gray-light-2;

		border: solid 1px $gray-light-2;
		padding: 20px;

		text-transform: uppercase;

		color: $dark-blue-1;

		display: flex;
		align-items: center;
		justify-content: center;

		transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1); 

		letter-spacing: 3px;

		span {
			margin-right: 10px;
		}

		@include mq ('tablet'){
			&:hover{
				background-color: $dark-blue-1;
				color: #fff;
			}
		}

 @include mq("tablet", max) {
    position: fixed;
    right: 20px;
    bottom: 30px;
    z-index: 11;
    will-change: transform;
    transition: all 0.2s ease,
      transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);

    width: 82px;
    height: 46px;
    background-color: $gray-light-2;
    border-radius: 24px;
    text-align: center;
    transition-delay: 0.4s;

    span {
      display: none;
    }

    svg {
      transition: transform 0.8s cubic-bezier(0.645, 0.045, 0.355, 1);
      transform: rotate(0) scale(1);
    }

    &.open {
      background-color: white;
      color: #111;
      width: 64px;

      transition-delay: 0.4s;
      transform: translateX(-10px);

      svg {
        transform: rotate(225deg) scale(1.5);
      }
    }
  }
}	