@import "./reset.scss";
@import "./_mixins.scss";

$enable-shadows: true;

$dark-blue-1: #002159;
$dark-blue-2: #01337D;
$dark-blue-3: #03449E;

$light-blue-1: #E6F6FF;

$white-1: #ffffff;
$gray-dark-1: #1F2933;
$gray-light-2: #E4E7EB;

$red-dark-1: #CF1124;

$accent-color-1: #0FB5BA;
$accent-color-2: #DE3A11;
$accent-color-3: #DE911D;
$accent-color-4: #2186EB;

@font-face {
  font-family: 'Mona Sans';
  src:
    url('../../public/fonts/Mona-Sans.woff2') format('woff2 supports variations'),
    url('../../public/fonts/Mona-Sans.woff2') format('woff2-variations');
  font-weight: 200 900;
  font-stretch: 75% 125%;
}


.accent-color-1 { color: $accent-color-1; }

.accent-color-2 { color: $accent-color-2; }

.accent-color-3 { color: $accent-color-3; }

.accent-color-4 { color: $accent-color-4; }


.bg-dark-blue { background-color: $dark-blue-1; }

.centeredBlock { display: flex;
align-items: center;
justify-content: center; 
}





/** * { outline: 1px solid red; } **/

strong { font-weight: bold; }


a {  }

a.blueLink { color:$dark-blue-3; }

p { line-height: 1.5; margin-bottom: 1.5rem; }

p.larger-text { font-size: 2.8rem; }

.circle-number-white { margin-right:5px; display:inline-block; color:#fff; width:30px; height:30px; line-height:30px; text-align: center; background-color:$dark-blue-1; border:solid 1px $dark-blue-1; border-radius:50%; -moz-border-radius:50%; -webkit-border-radius:50%; }



/** a:hover { text-decoration: underline; } **/

html {
	font-size: 62.5%;
}

body {  
	font-family: 'Mona Sans';
    font-size: 1.6rem;
    font-weight: 400;
    color: $gray-dark-1;
    line-height: 1.414;


	background-image: url('../../public/images/background-pattern.png');
	background-color: $dark-blue-1;
	-webkit-transition: all 1s linear;
    -moz-transition: all 1s linear;
    -o-transition: all 1s linear;
    transition: all 1s linear;

}

body.dark {  
	background-image: url('../../public/images/background-pattern.png');
	background-color: $dark-blue-1;
}

body.light {  
	background-image: none; 
	background-color: $white-1;
}


/** Typography **/
h2 {
		font-size:5rem;
		font-weight: 800;
		line-height: 1.1;
	}


h3 {
		font-size:4.2rem;
		font-weight: 800;
		line-height: 1.1;
	}


h4 {
		font-size:2.4rem;
		font-weight: 500;
		line-height: 1.5;
	}

.headlineGradient {
		background: linear-gradient(70deg, #03449E, #1CD4D4);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
}

.headlineGradientLight {
		color: $light-blue-1;
		background: linear-gradient(70deg, $light-blue-1, #7CC4FA);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;
}


.container-fluid { padding: 0; }
.row { margin: 0; }


#wrapper {
    /** background-image: url(); **/
    min-height: 100vh;
}


.zindexSidebar { z-index: 1; }


.main-container { 
	
	position: relative;

	background-color: $dark-blue-1;
	background-image: url('../../public/images/background-pattern.png');

	h1 { 
		font-size: clamp(50px, 10vw, 10vw);
		line-height:1;
		font-weight: bold;
		max-width: 70vw;
		color: $white-1;
	}

		.svg-container {
			svg, h1 {
			width: 95%;
			height: 95%;
			}
		}


#sectionLetDoSomething {
	/** background-image: url('../../public/images/background-pattern.png'); **/
	background-color: $white-1;

	padding: 2rem;

	.textArea { padding: 3rem 0; }

	h2 {
		padding-bottom: 2rem;		
			.redText { color:$red-dark-1; font-style: italic; }
	}

	p { 
		font-size: 2rem;
		padding-bottom: 2rem;
	}

	.codeText {
		font-family: 'Courier';
	}

	.customersText {
		font-weight: 200;
		font-style: italic;
	}

	.badge { 
			font-size: 1.25rem;
			padding: 1rem 2.5rem;
			margin-bottom: 2rem;
	 }

		@include mq ('tablet', min) {
			
			.textArea { padding: 5rem; }

			

			p { 
			font-size: 3rem;
			}

			.badge { 
			font-size: 2rem;
			}

		}


}		

#sectionTestimonial {
	padding: 5rem 0;
	
	.testimonialTextArea{
		position: relative;
	}

	.quoteMark 
	{
		display: inline;

		&:before {
			font-family: 'Times New Roman';
			content: open-quote;
			font-weight: bold;
    		font-size: 15rem;
    		color: rgba(186, 227, 255, 0.5);
    		position: absolute;
    		top:-75px;
    		left:0;
    		z-index: 0;


		}
	}

	p.testimonialQuote {
		font-family: 'Georgia';
		font-style: italic;
		font-weight: 400;
		font-size: 2.5rem;
		line-height: 1.5;
		padding-top: 0rem;
		z-index: 10;
		max-width: 1400px;
	}
	p.testimonialSpeaker {

		strong { color: $dark-blue-1; }

	}

	@include mq ('tablet', min) {
		
		.quoteMark 
		{
		display: inline;

		&:before {
			font-size: 25rem;
			position: absolute;
			top:-125px;
			left:-90px;

		}
		}

		p.testimonialQuote {
			
			font-size: 3.5rem;
		}

	}

}

#sectionWhatWeOffer { 
	border-top: solid 1px $dark-blue-1;
	background-color: $white-1;
	padding: 2rem;

	.offerRow { padding: 6rem 0; }	
}


@include mq ('tablet', min) {
	
	border-left: 1px solid $gray-light-2;

	.sectionHome, #main-footer, #copyright-area { padding-left: 5rem; }

	}

#sectionCustomerLogos {
	h2 {
		color: $light-blue-1;
		background: linear-gradient(70deg, $light-blue-1, #7CC4FA);
		-webkit-background-clip: text;
		-webkit-text-fill-color: transparent;

	}
}


}


.typingScreen { 
	margin: 4rem 0;
	width:100%;
	height:300px;

	background-color: rgb(30, 41,59);
	border: solid 1px rgb(229,231,235);
	border-radius: 12px;

	overflow-y: hidden;

	.typingScreenTop {
		border-bottom: solid 1px rgb(100,116,139, 0.3);
	}

	.typingScreenTopCircle {
		background-color: rgb(71, 85, 105);
		border: solid 0px rgb(229,231,235);
		border-radius:9999px;
		width: 10px;
		height: 10px;
	}

	.typingScreenNumbers { width: 50px; font-family: 'Courier'; color:rgb(71, 85, 105); display: block; text-align: right; padding: 0 1.5rem 1rem 1.5rem; }

	.Typewriter__wrapper { font-family: 'Courier'; color: $white-1; 

		.var-highlight{
			color: #C0AD60;
		}
		.string-highlight{
			color: rgba(253, 149, 90, 0.8);
		}	

	}

 }



.maskImage { mask-image: linear-gradient(to bottom, white, white, transparent);
    -webkit-mask-image: linear-gradient(to bottom, white, white, transparent);
}

.sectionWhatWeOfferFeaturedImages {

	img { border: solid 1px $gray-light-2; border-radius: 0.5rem; box-shadow: 0 20px 25px -5px #0000001a,0 8px 10px -6px #0000001a; }

}



/** interior pages **/

/** about page **/
.about-top-section { 
	background-color: $white-1;
}


/** our clients page **/
.client-box {
    margin-bottom: 2em;
    padding: 0 3rem 1rem 0;
}





.hero-gradient {
	z-index: 1;
    position: absolute;
    left: 35%;
    top: 5%;
    right: auto;
    bottom: auto;
    width: 45rem;
    height: 45rem;
    border-radius: 900rem;
    background-color: $dark-blue-2;
    filter: blur(7.5rem);
    transform: rotate(25deg);
}

@media screen and (max-width: 479px){

	.svg-container {
		margin-bottom: 8rem;
	}

	.hero-gradient {
    left: -47%;
    top: 1%;
    right: auto;
    bottom: auto;
    z-index: 0;
    width: 22rem;
    height: 29rem;
    border-radius: 100rem;
    filter: blur(3.75rem);
}
}
@media screen and (max-width: 767px){

	.hero-gradient {
	    left: -44%;
	}


}
@media screen and (max-width: 991px){
.hero-gradient {
    left: -25%;
    top: 1%;
    right: auto;
    bottom: auto;
    width: 33rem;
    height: 34rem;
}
}

@media screen and (min-width: 768px){

		body.light { .main-container {
				
				border-left: 1px solid $dark-blue-3;

			}	
		}

		h2 {
					font-size:7rem;
					}

		#wrapper {
		    padding-left: 70px;
		}

		.paddingLeft { padding-left: 5rem; }

}


@media screen and (min-width: 992px){

		.sectionCustomerLogosHeadlineRow {
			width: 75%;
			}

}


@media screen and (min-width: 1200px){

		.sectionCustomerLogosHeadlineRow {
		width: 50%;
		}
		#sectionCustomerLogos {
		.col-md-2 { padding: 1rem 2rem;}
		}
}

@media screen and (min-width: 1440px){

		.sectionCustomerLogosHeadlineRow {
		width: 50%;
		}
		#sectionCustomerLogos {
		.col-md-2 { padding: 2rem 4rem;}
		}

}


@media screen and (min-width: 1920px){

		.sectionCustomerLogosHeadlineRow {
		width: 50%;
		}
		
		#sectionCustomerLogos {
		.col-md-2 { padding: 4rem 8rem;}
		}
}