$white-1: #ffffff;
$dark-gray: #1F2933;
$dark-blue-1: #002159;

.brand-description { 

	position: fixed;
	z-index: 11;
	top: 50px;
	right: 7vw;
	max-width: 150px;

	color: $white-1;

	text-transform: uppercase;

	font: {
		size: 14px;
	}

	text-align: right;
}

body.light {  

	.brand-description { 
			// color:$dark-blue-1;
	}

}

@media screen and (min-width: 479px){

		.brand-description { 
		position: fixed;
		z-index: 11;
		top: 100px;
		right: 7vw;
		max-width: 150px;
		}

}